.root {
	font-size: 25px;
	line-height: 160%;
	padding-block: var(--space-lg);

	@media (--md) {
		font-size: 50px;
		line-height: 140%;
		padding-block: var(--space-xl);
	}
}
