.root {
	--border-color-active: var(--primary-dark-blue-04);
	--border-active: 1px solid var(--border-color-active);
	--padding: 14px 24px;
	--border: 1px solid var(--border-color);
	--border-color: var(--primary-dark-blue-01);

	@media (--md) {
		--padding: 16px 24px;
	}

	width: 100%;
	position: relative;

	@media (--md) {
		max-width: 310px;
	}

	& .primitive {
		width: 100%;
		padding: var(--padding);
		display: flex;
		align-items: center;
		justify-content: space-between;
		border: var(--border);
		border-radius: var(--border-rounded);
		transition: var(--transition-colors);
		background-color: var(--primary-white);

		@media (hover: hover) {
			&:hover {
				background-color: var(--primary-dark-blue-005);
				border: var(--border-active);
			}
		}
	}

	& .options {
		width: 100%;
		position: absolute;
		margin-top: var(--space-2xs);
		padding: 0;
		z-index: var(--z-above);
		background: var(--primary-white);
		border-radius: 8px;
		border: var(--border-active);
		overflow: hidden;
	}

	& .open {
		opacity: 1;
		transform: scaleY(1);
	}

	& .label {
		margin: 0;
		border: 0;
		padding: 0 0 10px 6px;
	}

	& .radioButton {
		--size: 18px;
		--inner-size: 12px;
		--outer-color: var(--primary-dark-blue);
		--inner-color: var(--primary-dark-blue);
		--gap-color: white;

		width: var(--size);
		height: var(--size);
		align-items: center;
		justify-content: center;
		background-color: var(--gap-color);
		border: 1px solid var(--outer-color);
		border-radius: 50%;
		contain: strict;
		box-sizing: border-box;
	}

	& .trigger {
		.radioButton {
			display: none;
		}

		& > * {
			padding: 0;
		}

		p {
			@extend %typo-utility-3;

			color: var(--primary-dark-blue);

			@media (--md) {
				@extend %typo-utility-4;
			}
		}
	}

	[data-active-item="true"] {
		& .radioButton {
			display: flex;
			flex-shrink: 0;
		}

		& .radioButton::before {
			content: "";
			width: var(--inner-size);
			height: var(--inner-size);
			background-color: var(--inner-color);
			border-radius: 50%;
			flex-shrink: 0;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.option {
	padding: 10px 15px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-bottom: 1px solid var(--primary-dark-blue-005);
	gap: 7.5px;
	color: var(--primary-dark-blue-08);

	&[data-highlighted="true"] {
		background-color: var(--primary-dark-blue-005);
	}

	&:last-child {
		border-bottom: none;
	}

	@media (hover: hover) {
		&:hover {
			background: var(--primary-dark-blue-005);
		}
	}
}

.scrollRoot {
	max-height: 250px;
}

.scrollViewport {
	max-height: 250px;
	padding: var(--space-xs) 0;
	overflow-y: auto;
}

.large {
	max-width: 100%;
}

.muted {
	color: var(--primary-dark-blue-06);
}

@keyframes content-show {
	from {
		opacity: 0;
		transform: translateY(-4px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}

.comboboxInput {
	width: 100%;
	max-width: 100%;
	position: sticky;
	top: 0;
	padding: var(--space-xs) var(--space-sm);
	border: 0;
	appearance: none;
	border-bottom: var(--border);

	@media (--max-sm) {
		font-size: 16px;
	}

	font-size: 14px;
}
