.root {
	width: 100%;
	font-size: var(--body-2-size);

	& :where(b) {
		font-weight: 600;
		font-family: inherit;
	}

	& :where(h1) {
		@extend %typo-heading-1;

		margin-block-end: 0.25em;
	}

	& :where(h2) {
		@extend %typo-heading-3;

		margin-block-end: 0.25em;
	}

	& :where(h3) {
		@extend %typo-heading-4;

		margin-block-end: 0.25em;
	}

	& :where(h4) {
		@extend %typo-heading-5;

		margin-block-end: 0.25em;
	}

	& :where(h5) {
		@extend %typo-heading-6;

		margin-block-end: 0.25em;
	}

	& :where(h6) {
		@extend %typo-heading-6;

		margin-block-end: 0.25em;
	}

	& :where(blockquote) {
		@extend %typo-heading-5;

		position: relative;

		&::before {
			content: "“";
			position: absolute;
			left: -0.5em;
		}
	}

	& :where(p) {
		@extend %typo-body-1;

		margin-block: 0 1em;
	}

	&:where(.small) {
		& :where(h1) {
			@extend %typo-heading-2;

			margin-block-end: 0.25em;
		}

		& :where(h2) {
			@extend %typo-heading-4;

			margin-block-end: 0.25em;
		}

		& :where(h3) {
			@extend %typo-heading-5;

			margin-block-end: 0.25em;
		}

		& :where(h4) {
			@extend %typo-heading-6;

			margin-block-end: 0.25em;
		}

		& :where(h5) {
			@extend %typo-heading-6;

			margin-block-end: 0.25em;
		}

		& :where(h6) {
			@extend %typo-heading-6;

			margin-block-end: 0.25em;
		}

		& :where(blockquote) {
			@extend %typo-heading-6;

			position: relative;

			&::before {
				content: "“";
				position: absolute;
				left: -0.5em;
			}
		}

		& :where(p) {
			@extend %typo-body-2;

			margin-block: 0 1em;
		}
	}

	& :where(p) > span[style] {
		padding-inline: 3px;
		border-radius: 4px;
	}
}
