.carousel {
	--slide-size: calc(100% / 1.5);

	@media (--md) {
		--slide-size: calc(100% / 4);
	}

	& .viewport {
		padding-left: var(--slide-spacing);
	}
}

.scrollbar {
	width: 100%;
	max-width: 200px;
	height: 2px;
	position: relative;
	margin-inline: auto;
	margin-top: 15px;
	border-radius: 4px;
	background-color: var(--primary-grey);

	& .track {
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: var(--primary-dark-blue);
		transition: var(--transition-standard);
		transition-property: transform;
		transition-duration: 150ms;
	}
}
