.root {
	--gap: var(--space-md);

	max-width: var(--text-width);
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	gap: var(--gap);

	@media (--md) {
		--gap: var(--space-lg);
	}

	& .items {
		--gap: var(--space-2xs);

		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		gap: var(--gap);

		@media (--md) {
			--gap: var(--space-sm);
		}
	}
}
