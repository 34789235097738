:where(.root) {
	--slide-spacing: 1rem;
	--slide-size: 100%;

	position: relative;
	margin: auto;
	isolation: isolate;
	cursor: pointer;
}

.viewport {
	overflow: hidden;
}

.container {
	margin-left: calc(var(--slide-spacing) * -1);
	display: flex;
	touch-action: pan-y pinch-zoom;
	align-items: flex-start;
}

.slide {
	min-width: 0;
	padding-left: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translate3d(0, 0, 0);
	flex: 0 0 var(--slide-size);
}

.control.control {
	position: absolute;
	top: calc(50% - (var(--icon-button-size) / 2));
	display: none;
	z-index: var(--z-sticky);
	transform: translateY(-50%);

	&:disabled {
		--svg-color: currentcolor;

		color: #b6b4b5;
	}

	@media (--md) {
		display: flex;
	}
}

.prevButton {
	left: 25px;
}

.nextButton {
	right: 25px;
}
