.root {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-template-rows: repeat(2, 1fr);
	gap: 15px;

	@media (--md) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(1, 1fr);
	}
}

.searchLabel {
	padding: 0 0 10px 6px;
}

.search {
	height: 100%;
	gap: 0;
}

.searchInput {
	height: 100%;

	::placeholder {
		@extend %typo-utility-3;

		@media (--md) {
			@extend %typo-utility-4;
		}
	}
}
