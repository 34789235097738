.title {
	margin-inline: var(--space-sm);
	margin-bottom: var(--space-sm);

	@media (--lg) {
		margin: 0 auto;
		margin-bottom: var(--space-lg);
	}
}

.list {
	--gap: 5px;

	display: flex;

	/* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
	flex-direction: row;
	gap: var(--gap);
	overflow: visible;

	@media (--lg) {
		--gap: 16px;

		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
}

.skeleton {
	height: auto;
	border-radius: 4px;
	aspect-ratio: 203 / 255;
}

.card {
	width: 100%;
	aspect-ratio: 203 / 255;
	position: relative;
	display: block;
	flex-shrink: 0;
	flex-basis: 40%;

	/* flex: 1 0 40%; */
	background-color: rgb(211 211 211 / 50%);
	overflow: hidden;
	border-radius: 4px;

	& img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}

@media (--max-md) {
	.scrollarea {
		max-width: 100%;
	}

	.list {
		&::before,
		&::after {
			width: var(--page-inset);
			min-width: var(--page-inset);
			height: 1px;
			content: "";
			flex-shrink: 0;
		}
	}
}

.scrollarea {
	margin-top: 0;
}
