.root {
	& .header {
		--margin-bottom: 15px;
		--padding-inline: 15px;

		margin-bottom: var(--margin-bottom);
		padding-inline: var(--padding-inline);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 15px;

		@media (--md) {
			--padding-inline: 30px;
			--margin-bottom: 30px;

			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 0;
		}
	}

	& .scrollWrapper {
		width: 100%;
		padding-inline: 15px;
		display: flex;
		gap: 2px;

		& .scrollProduct {
			width: 100%;
			min-width: 70%;
			height: 100%;
			flex-grow: 1;
		}
	}

	& .mobile {
		display: block;

		@media (--md) {
			display: none;
		}
	}

	& .desktop {
		display: none;

		@media (--md) {
			display: block;
		}
	}
}

.viewAll {
	--margin-top: 15px;

	margin-top: var(--margin-top);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: inherit;
	grid-column: 3;

	@media (--lg) {
		--margin-top: 30px;
	}

	& .viewAllText {
		--size: 11px;

		font-size: var(--size);
		line-height: 1;
		white-space: nowrap;

		@media (--lg) {
			--size: 13px;
		}
	}
}
