.root {
	--padding: 15px;

	width: 100%;
	max-width: 100%;
	padding: var(--padding);
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow: hidden;
	background: var(--primary-white);
	border-radius: 4px;

	@media (--md) {
		--padding: 25px;
	}

	& .link {
		text-decoration: underline;
	}
}

.openingHours {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;
}
