.root {
	--slide-gap: 5px;
}

.title {
	margin-inline: var(--space-sm);
	margin-bottom: var(--space-sm);

	@media (--lg) {
		margin: 0 auto;
		margin-bottom: var(--space-lg);
	}
}

.list {
	display: flex;
	gap: var(--slide-gap);

	&::before,
	&::after {
		width: var(--page-inset);
		height: 1px;
		content: "";
		flex-shrink: 0;
	}

	& > * {
		flex-shrink: 0;
		flex-basis: 60%;
	}

	@media (--md) {
		display: grid;
		gap: var(--slide-gap);
		grid-template-columns: repeat(4, 1fr);

		&::before,
		&::after {
			display: none;
		}
	}
}
